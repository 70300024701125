






































































import Vue from 'vue';
import difference from 'lodash/difference';
const leftTableColumns = [
  {
    dataIndex: 'userCode',
    title: '登录账号',
  },
  {
    dataIndex: 'realName',
    title: '姓名',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'userCode',
    title: '登录账号',
  },
  {
    dataIndex: 'realName',
    title: '姓名',
  },
];
export default Vue.extend({
  props: {
    transferData: {
      type: Array,
      default: () => [],
    },
    transferTargetKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      targetKeys: [],
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
    };
  },
  watch: {
    transferTargetKeys(val) {
      if (val) {
        this.targetKeys = val;
      }
    },
  },
  methods: {
    getAddIds(a, b) {
      return a.filter((item) => {
        return b.indexOf(item) === -1;
      });
    },
    getDeleteIds(a, b) {
      return b.filter((item) => {
        return a.indexOf(item) === -1;
      });
    },
    handleOk() {
      const addIds = this.getAddIds(this.targetKeys, this.transferTargetKeys);
      const deleteIds = this.getDeleteIds(
        this.targetKeys,
        this.transferTargetKeys
      );
      const currentData = {
        addIds: addIds,
        deleteIds: deleteIds,
      };
      // console.log('addIds', addIds);
      // console.log('deleteIds', deleteIds);
      // console.log('currentData', currentData);

      this.$emit('handleSaveDatas', currentData);
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          // console.log(selected);
          // console.log(selectedRows);

          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
});
